html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Nunito Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nunito Sans", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  body {
    @apply overflow-x-hidden 
  }

}
.animate-pulldown {
  animation: pulldown 1s ease-in-out 1.2s forwards;
}

.customUnderline {
  position: relative;
}

nav .customUnderline-active:before {
  visibility: visible;
  transform: scaleX(1);
}

.customUnderline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  /* transform-origin: left; */
  transition: all 0.2s ease-in-out;
}
.customUnderline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.acc-cont {
  height: 1150px;
}

@media (min-width: 768px) {
  .acc-cont {
    height: 868px;
  }
}

.animate-appearup,
.animate-appearup-2 {
  animation: appearup 0.8s ease-out 1s forwards;
}

.animate-appearup-2 {
  animation-delay: 1.2s;
}

.animate-moveleft {
  animation: moveleft 0.8s ease-out 0.5s forwards;
}

.animate-moveup {
  animation: moveup 0.8s ease-out 0.3s forwards;
}
.animate-cookie .CookieConsent {
  justify-content: center !important;
  opacity: 0;
  align-items: center;
  background: #2f91b8;
  color: white; /* justify-content: space-between; */
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 1200;
  font-size: 16px;
  /* padding-left: 40px;padding-right: 40px;padding-top: 20px; */
  bottom: 0px;
  animation: moveup 0.8s ease-out 3s forwards;
}
.animate-cookie .CookieConsent button {
  background: #1782AA;
  border: 0px;
  border-radius: 16px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  /* flex: 0 0 auto;  */
  padding: 6px 16px;
  margin-left: 8px;
  /* margin-bottom: 4px; */
  font-size: 15px;
}

.animate-grow {
  animation: grower 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-movearound {
  animation: movearound 15s linear infinite;
}

.curved-img {
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
}

.team::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.team::-webkit-scrollbar-track {
  width: 0px;
}

/* Handle */
.team::-webkit-scrollbar-thumb {
  width: 0px;
}

.arrow {
  left: 12.5%;
  animation: dragleft 5s infinite linear;
}

@media (min-width: 768px) {
  .gray-bg {
    animation: grayer 5s infinite linear;
  }
}

.gray-bg--1 {
  animation-delay: 0.1s;
}

.gray-bg--2 {
  animation-delay: 1.65s;
}

.gray-bg--3 {
  animation-delay: 3.3s;
}

.gray-bg--4 {
  animation-delay: 4.9s;
}

.float {
  width: 35px;
  height: 35px;
  position: absolute;
  background-size: 100% 100%;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
}

.float-react {
  background: url(./assets/images/stacks/react.png);
}

.float-angular {
  background: url(./assets/images/stacks/angular.png);
}

.float-vue {
  background: url(./assets/images/stacks/vue.png);
}

.float-go {
  background: url(./assets/images/stacks/go.png);
}

.float-php {
  background: url(./assets/images/stacks/php.png);
}

.float-node {
  background: url(./assets/images/stacks/node.png);
}

.float-laravel {
  background: url(./assets/images/stacks/laravel.png);
}

.float-html {
  background: url(./assets/images/stacks/html.png);
}

.float-css {
  background: url(./assets/images/stacks/css.png);
}

.float-js {
  background: url(./assets/images/stacks/js.png);
}

.testimony {
  min-height: 340px;
}

@media (min-width: 768px) {
  .testimony {
    min-height: 290px;
  }
}

.needle {
  transform-origin: center;
  animation: spin 3.5 1;
  -webkit-animation: spin 3.5s 1;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

@keyframes appearup {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes pulldown {
  from {
    transform: translateY(-100%);
    opacity: 0.2;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveleft {
  from {
    opacity: 0.1;
    transform: translateX(60%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes moveup {
  from {
    opacity: 0.1;
    transform: translateY(60%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes dragleft {
  0% {
    left: 12.5%;
    background-color: rgb(255, 0, 191);
  }

  33% {
    left: 37.5%;
    background-color: rgb(89, 0, 255);
  }

  66% {
    left: 62.5%;
    background-color: rgb(0, 89, 255);
  }

  100% {
    left: 88.5%;
    background-color: rgb(10, 224, 10);
  }
}

@keyframes grayer {
  0% {
    background-color: rgb(124, 148, 184);
  }
  30% {
    background-color: rgb(124, 148, 184);
  }
  70% {
    background-color: transparent;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes grower {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes movearound {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

/* external css: flickity.css */

* {
  box-sizing: border-box;
}
